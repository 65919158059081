import { log } from '../helpers';

/**
 * Gets onboarding tags
 * @return object
 */
function getOnboardingTags($nodeAxios) {
  return $nodeAxios.get('/api/v1/onboarding/tags')
      .then(response => response.data)
      .catch(error => log('getOnboardingTags', error));
}

/**
 * Search for onboarding tags by section
 */
function searchOnboardingTags({ pp, off, search, section, $nodeAxios }) {
  return $nodeAxios.get(`/api/v1/home/explore/?limit=${pp}&offset=${off}&sub_types[]=any&types[]=tags&search=${search}&section=${section}`, {})
        .then(response => response.data)
        .catch(error => log('searchOnboardingTags', error));
}

/**
 * Follow a bulk list of onboarding tags
 */
function followOnboardingTags(tags, $nodeAxios) {
  return $nodeAxios.post('/api/v1/onboarding/tags/follow', { tag_ids: tags })
        .then(response => response.data)
        .catch(error => log('followOnboardingTags', error));
}

/**
 * Gets onboarding recommended groups for user
 * @return object
 */
function getOnboardingRecommendedGroups($nodeAxios) {
  return $nodeAxios.get('/api/v1/onboarding/recommendations/groups')
        .then(response => ({ key: 'groups', list: response.data }))
        .catch(error => log('getOnboardingTags', error));
}

/**
 * Gets onboarding recommended users for user
 * @return object
 */
function getOnboardingRecommendedUsers($nodeAxios) {
  return $nodeAxios.get('/api/v1/onboarding/recommendations/users')
  .then(response => ({ key: 'members', list: response.data }))
        .catch(error => log('getOnboardingTags', error));
}

/**
 * Gets onboarding recommended topics for user
 * @return object
 */
function getOnboardingRecommendedTopics($nodeAxios) {
  return $nodeAxios.get('/api/v1/onboarding/recommendations/topics')
  .then(response => ({ key: 'hashtags', list: response.data }))
        .catch(error => log('getOnboardingTags', error));
}


/**
 * Join the groups that the user selects
 * Groups is an array of ids, ie. ['11', '12', '13]
 * @return object
 */
function joinGroups(groups, $nodeAxios) {
  return $nodeAxios.post('/api/v1/group/bulkDeferApply', { groups })
  .then(response => response)
  .catch(error => log('joinGroups', error));
}

/**
 * Follow the users that the user selects
 * @return object
 */
function followUsers(follows, $nodeAxios) {
  return $nodeAxios.post('/api/v1/follow/users', { follows })
  .then(response => response)
  .catch(error => log('followUsers', error));
}

/**
 * Follow the topics that the user selects
 * Follows is an array of { id }, ie. [{ id: '11' }, { id: '12' }, { id: '13' }]
 * @return object
 */
function followTopics(follows, $nodeAxios) {
  return $nodeAxios.post('/api/v1/follow', { follows })
  .then(response => response)
  .catch(error => log('followTopics', error));
}


export default {
  getOnboardingTags,
  searchOnboardingTags,
  followOnboardingTags,
  getOnboardingRecommendedGroups,
  getOnboardingRecommendedUsers,
  getOnboardingRecommendedTopics,
  joinGroups,
  followUsers,
  followTopics,
};
