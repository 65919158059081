
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';
import _ from 'lodash';
import segmentAnalytics from '@/libs/events';
import { tmAnalytics, staticPath, sendSegmentEvent } from '@/data/helpers';
import userApi from '@/data/api/user';
import onboardingApi from '@/data/api/onboarding';
import TagSection from './TagSection';
import DateSelector from './DateSelector';
import IntroductionPost from './IntroductionPost';
import Recommendations from './Recommendations';
import Subscribe from './Subscribe';
// if developing locally might need to use sampleData for tags (sections)
// import sampleData from './sampleData';

const https = require('https');

export default {
  name: 'Registration',
  props: ['isLargeScreen'],
  components: { TagSection, DateSelector, IntroductionPost, Recommendations, Subscribe },
  data() {
    return {
      email: '',
      username: '',
      userId: null,
      nonce: null,
      birthYear: null,
      acceptedTerms: false,
      action: 'registration',
      step: 0,
      disabledSubmit: false,
      errorUsername: false,
      errorEmail: '',
      underage: false,
      selectedTags: [],
      sections: [],
      // if we have new users who sign up via Social they need to validate their birthdate, storing the form in here for re-use
      socialCredentials: {},
      // different flow for social onboarding (ie. skips email input)
      socialOnboarding: false,
      redirecting: false,
      openAccordions: [],
      recommended: [],
      doneLoadingRecommended: false,
      doneLoadingAnimation: false,
      suggestedUsernames: [],
      loadingUsernames: false,
    };
  },
  watch: {
    modalArgs: {
      immediate: true,
      handler() {
        const { action, source } = this.modalArgs;
        if (action) {
          // if on the open modal there is a specific action (ie. Registration vs Login), use that
          this.action = action;
        }

        // if from content gate, skip straight to birthdate input
        if (source === 'content-gate' && action === 'registration') {
          this.step = 1;
        }

        if (this.modalArgs.completeOnboarding) {
          if (process.browser) {
            if (!localStorage.getItem('underage')) {
              this.socialOnboarding = true;
              this.action = 'registration';
              this.step = 3;
              this.getUserNonce();
            }
          }
        }
      },
    },
    screen: {
      immediate: true,
      handler() {
        if (process.browser) {
          if (this.screen === 'register') {
            tmAnalytics({ ga: this.$ga, category: 'registration.signup', action: 'view', label: window.page_data ? window.page_data.slug : '' }, ['ga']);
            segmentAnalytics.viewSignUp({});
            segmentAnalytics.choose({
              context: {
                section: 'registration.signup',
                view: 'new_onboarding',
              },
              target: {
                name: 'signup',
                type: 'registration',
              },
              sessionId: this.user.sessionId,
            });
          } else if (this.screen === 'follow-tags') {
            tmAnalytics({ ga: this.$ga, category: 'registration.follows', action: 'view', label: window.page_data ? window.page_data.slug : '' }, ['ga']);
          } else if (this.screen === 'login') {
            segmentAnalytics.choose({
              context: {
                section: 'registration.login',
                view: 'new_onboarding',
              },
              target: {
                name: 'login',
                type: 'registration',
              },
              sessionId: this.user.sessionId,
            });
          }

          // View events for each screen of Registration process
          segmentAnalytics.view({
            context: {
              step: this.step,
              screen: this.screen,
              view: 'new_onboarding',
            },
            sessionId: this.user.sessionId,
          });
        }
      },
    },
  },
  beforeMount() {
    this.underage = localStorage.getItem('underage') || false;
  },
  mounted() {
    // disables scrolling for whatever the underlying page is
    document.body.style.overflowY = 'hidden';
    // const redirectURI = [location.protocol, '//', location.host, '/'].join('');
    // window.AppleID.auth.init({
    //   clientId: 'com.themighty.TheMightyWeb', // This is the service ID we created.
    //   scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
    //   redirectURI, // As registered along with our service ID
    //   state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
    //   usePopup: true, // Important if we want to capture the data apple sends on the client side.
    // });
  },
  computed: {
    ...mapGetters({
      user: 'user',
      openModal: 'getOpenModal',
      openModalArgs: 'getOpenModalExtraArgs',
    }),
    open() {
      return this.openModal === 'login-registration-onboarding';
    },
    modalArgs() {
      if (this.open) {
        return this.openModalArgs;
      }
      return {};
    },
    showSignUpHeader() {
      if (!this.action === 'registration') return false;
      if (this.socialOnboarding) return this.step === 4;
      return this.step > 0 && this.step < 5;
    },
    screen() {
      if (this.action === 'login') {
        return 'login';
      }

      if (this.underage) {
        return 'underage';
      }

      switch (this.step) {
        case 0:
          return 'register';
        case 1:
          return 'birthdate-form';
        case 2:
          return 'email-input';
        case 3:
          return 'username-input';
        case 4:
          return 'follow-tags';
        case 5:
          return 'finding-connections';
        case 6:
          return 'newsletters';
        case 7:
          return 'congratulations';
        default:
          break;
      }

      return 'register';
    },
    validUsername() {
      return /^[a-zA-Z0-9_]*$/.test(this.username);
    },
    hasAcceptedTerms() {
      return !this.acceptedTerms;
    },
    disableBirthYearSubmit() {
      return !this.acceptedTerms || _.isNil(this.birthYear) || this.disabledSubmit;
    },
    disableEmailSubmit() {
      return _.isEmpty(this.email) || this.disabledSubmit;
    },
    disableUserSettingsForm() {
      return this.username.length < 4 || !this.validUsername || this.disabledSubmit;
    },
    showRecommendations() {
      return this.doneLoadingRecommended && this.doneLoadingAnimation;
    },
    introductionPostTags() {
      return _.filter(this.selectedTags, tag => tag.section === 'I live with or have been diagnosed with...');
    },
    screenHeader() {
      return this.redirecting ? 'Redirecting to...' : 'Welcome to';
    },
  },
  methods: {
    staticPath,
    setAction(action) {
      this.action = action;
    },
    async validateBirthdate() {
      const today = new Date();
      // Liz wanted to store the dob as 12/31/{birthYear}
      const dob = new Date(`December 31, ${this.birthYear}`);
      const age = today.getFullYear() - dob.getFullYear();
      const underage = age < 17;
      if (underage) {
        localStorage.setItem('underage', true);
        this.underage = true;
        this.step = 0;
      } else if (this.socialCredentials.platform) {
        this.disabledSubmit = true;
        // essentially checking if we stored social credentials we collected earlier, so we can now officially create the user
        await this.loginUser(this.socialCredentials);
      } else {
        this.step += 1;
        segmentAnalytics.choose({
          context: {
            section: 'join_enter_birthday_card',
            view: 'onboarding_req',
          },
          target: {
            name: 'Continue',
            type: 'button',
          },
          user: {
            birthday_date: new Intl.DateTimeFormat('en-US').format(dob),
            age,
          },
          sessionId: this.user.sessionId,
        });
      }
    },
    setToRegistration() {
      this.step = 0;
      this.action = 'registration';
      this.errorEmail = '';
    },
    setBirthYear(year) {
      this.birthYear = year;
    },
    continueWithEmail() {
      this.intentLoginSignUp('email');
      if (this.action === 'login') {
        this.login();
      } else {
        this.step += 1;
      }
    },
    async login() {
      this.disabledSubmit = true;
      this.errorEmail = '';
      let validEmail = true;

      await userApi.confirmEmail(this.email, this.$nodeAxios)
        .then((response) => {
          if (response.error === 'User not found.') {
            this.errorEmail = 'user-not-found';
            validEmail = false;
          } else {
            segmentAnalytics.logIn({
              target: {
                name: 'continue_with_email',
                // name: 'login',
              },
              sessionId: this.user.sessionId,
            });
          }
        });

      if (!validEmail) {
        this.disabledSubmit = false;
        return;
      }

      const args = { email: this.email };
      if (process.browser) {
        args.referrer = window.location.href.split('?')[0];
      }
      
      userApi.registerUser(args, this.$wpAxios)
        .then((response) => {
          if (response.G === 'sent-magic-link') {
            tmAnalytics({ ga: this.$ga, category: 'login.email', action: 'choose', label: window.page_data ? window.page_data.slug : '' }, ['ga']);
            this.action = 'login';
            this.step = 1;
          } else if (response.G === 'account-suspended') {
            this.errorEmail = 'account-suspended'
          }
          this.disabledSubmit = false;
        });
    },
    async registerEmail() {
      this.disabledSubmit = true;
      this.errorEmail = '';

      const args = { email: this.email, day: '31', month: '12', year: this.birthYear };
      if (process.browser) {
        args.referrer = window.location.href.split('?')[0];
      }
      
      userApi.registerUser(args, this.$wpAxios)
        .then((response) => {
          /* eslint no-underscore-dangle: 0 */
          if (!response.S && _.includes(response.E, '((invalid:email))')) {
            this.errorEmail = 'Sorry. That is not a valid email. (example format: username@email-domain.com)';
          }
          if (!response.S && _.get(response, 'E[0]', '').includes('The email you provided is not valid.')) {
            this.errorEmail = response.E[0];
          }
          if (response.new) {
            segmentAnalytics.signUp({
              context: {
                section: 'email',
                view: 'new_account',
              },
              sessionId: this.user.sessionId,
            });
            // using the display_name as it's Camel Case unlike the username
            if (response.U.display_name) {
              this.suggestedUsernames.push(response.U.display_name);
            }
            this.getUserNonce();
            this.step += 1;
          } else if (response.G === 'sent-magic-link') {
            this.action = 'login';
            this.step = 1;
          }
          segmentAnalytics.choose({
            context: {
              section: 'join_email',
              view: 'onboarding_req',
            },
            target: {
              name: 'Continue',
              type: 'button',
            },
            sessionId: this.user.sessionId,
          });
          tmAnalytics({ ga: this.$ga, category: 'login.email', action: 'choose', label: window.page_data ? window.page_data.slug : '' }, ['ga']);
          this.disabledSubmit = false;
        });
    },
    async refreshUsername() {
      if (this.suggestedUsernames.length > 1) {
        this.suggestedUsernames.shift();
      } else {
        // just to stop from a continuous clicking of the img
        if (this.loadingUsernames) {
          return;
        }
        this.loadingUsernames = true;
        await userApi.getSuggestedUsernames(this.email, this.$wpAxios)
          .then((response) => {
            if (response.usernames) {
              this.suggestedUsernames = response.usernames;
            }
          })
        this.loadingUsernames = false;
      }
    },
    submitUserSettings() {
      this.errorUsername = false;
      this.disabledSubmit = true;
      const { email, username, nonce } = this;
      const dob = `01/01/${this.birthYear}`;
      segmentAnalytics.choose({
        context: {
          section: 'join_display_name',
          view: 'onboarding_req',
        },
        target: {
          name: 'Continue',
          type: 'button',
        },
        sessionId: this.user.sessionId,
      });
      userApi.updateUser({ email, username, displayname: username, nonce, dob }, this.$wpAxios)
        .then((response) => {
          const index = _.findIndex(response.E, error => error === 'This username is already taken. Enter a unique username for yourself.');
          if (index > -1) {
            this.errorUsername = true;
            this.disabledSubmit = false;
            // if the username they attempted to submit was the suggested one, show a new suggestion 
            if (username === this.suggestedUsernames[0]) {
              this.refreshUsername();
            }
          } else {
            onboardingApi.getOnboardingTags(this.$nodeAxios).then((r) => {
              this.sections = r.sections;
              this.step += 1;
              this.disabledSubmit = false;
            });
          }
        });
    },
    isAccordionOpen(id) {
      return _.includes(this.openAccordions, id);
    },
    toggleAccordion(id) {
      const index = _.findIndex(this.openAccordions, a => a === id);
      if (index > -1) {
        this.openAccordions.splice(index, 1);
      } else {
        this.openAccordions.push(id);
      }
    },
    toggleTag({ id, label, section, sectionId }) {
      const index = _.findIndex(this.selectedTags, t => t.id === id);
      const selected = index !== -1;

      segmentAnalytics[!selected ? 'select' : 'deselect']({
        context: {
          section: 'join_why',
          view: 'onboarding_why',
          card: {
            type: 'featured_list',
          },
        },
        target: {
          name: label,
          type: section,
        },
        sessionId: this.user.sessionId,
      });
      if (selected) {
        this.selectedTags.splice(index, 1);
        tmAnalytics({ ga: this.$ga, category: 'registration.follows', action: 'unfollow', label }, ['ga']);
      } else {
        this.selectedTags.push({ id, label, section, sectionId });
        tmAnalytics({ ga: this.$ga, category: 'registration.follows', action: 'follow', label }, ['ga']);
      }
    },
    followTags() {
      this.disabledSubmit = true;
      segmentAnalytics.choose({
        context: {
          section: 'join_why',
          view: 'onboarding_why',
          card: {
            type: 'featured_list',
          },
        },
        target: {
          name: 'Continue',
          type: 'button',
        },
        onboarding: {
          selections_total: this.selectedTags.length,
        },
        sessionId: this.user.sessionId,
      });
      onboardingApi.followOnboardingTags(_.map(this.selectedTags, t => t.id), this.$nodeAxios)
        .then(async () => {
          this.step += 1;
          this.scrollToTop();
          Promise.all([onboardingApi.getOnboardingRecommendedGroups(this.$nodeAxios),
            onboardingApi.getOnboardingRecommendedUsers(this.$nodeAxios), onboardingApi.getOnboardingRecommendedTopics(this.$nodeAxios)])
              .then(async (res) => {
                // using this in case response returns empty array
                this.doneLoadingRecommended = true;
                this.recommended = res;
                await this.followRecommendedItems();
              });
        });
    },
    async followRecommendedItems() {
      const groups = _.map(_.find(this.recommended, r => r.key === 'groups').list, g => g._id);
      const users = _.map(_.find(this.recommended, r => r.key === 'members').list, u => ({ id: u._id }));
      const topics = _.map(_.find(this.recommended, r => r.key === 'hashtags').list, t => ({ id: t._id }));
      await Promise.all([onboardingApi.joinGroups(groups, this.$nodeAxios), onboardingApi.followUsers(users, this.$nodeAxios), onboardingApi.followTopics(topics, this.$nodeAxios)]);
    },
    recommendationCount(type) {
      const data = _.find(this.recommended, r => r.key === type) || [];
      return data ? _.get(data, 'list.length', 0) : 0;
    },
    async showNewsletters() {
      this.doneLoadingRecommended = false;
      await this.$nodeAxios.get(`/api/v1/user/${this.username.toLowerCase()}`)
          .then(response => { 
              const user = _.get(response, 'data.user');
              if (user) this.userId = user._id;
              this.step += 1;
          })
          .catch((err) => {
              this.step += 1;
          })
    },
    getUserNonce() {
      userApi.getUserSettings(this.$wpAxios).then((r) => {
        this.nonce = r.U._n;
        // if onboarding post Social sign up, need email for when submitting username
        if (this.socialOnboarding) {
          this.email = r.U.email;
          this.suggestedUsernames.push(r.U.user_login);
        }
      });
    },
    finishOnboarding() {
      // refresh page, removing query param in event the complete_onboarding query string exist
      window.location = window.location.href.split('?')[0];
    },
    close() {
      const body = document.body;
      // need to reset the overflow-y so original page can scroll
      body.style.removeProperty('overflow-y');
      // also removing register/login param if they came from that specific link
      if ("register" in this.$route.query || "login" in this.$route.query) {
        this.$router.replace(this.$route.path);
      }
      this.$store.dispatch('closeModal');
    },
    scrollToTop() {
      document.getElementById('registration-header-onboarding').scrollIntoView();
    },
    handleAcceptedTerms() {
      if (this.acceptedTerms) {
        segmentAnalytics.choose({
          context: {
            section: 'join_terms_privacy_agree',
            view: 'onboarding_req',
            card: {
              type: 'featured_list',
            },
          },
          target: {
            name: 'I_Agree',
            type: 'button',
          },
          sessionId: this.user.sessionId,
        });
      }
    },
    intentLoginSignUp(target) {
      const targetName = `continue_with_${target}`;

      if (this.action !== 'login') {
        segmentAnalytics.choose({
          context: {
            section: 'sign_up',
            view: 'welcome_screen',
            card: {
              type: 'featured_list',
            },
          },
          target: {
            name: targetName,
            type: 'button',
          },
          sessionId: this.user.sessionId,
        });
      } else {
        segmentAnalytics.logIn({
          context: {
            section: 'login',
            view: 'welcome_screen',
            card: {
              type: 'featured_list',
            },
          },
          target: {
            name: targetName,
            type: 'button',
          },
          sessionId: this.user.sessionId,
        });
      }
    },
    async googleLogin() {
      this.intentLoginSignUp('google');
      try {
        const googleUser = await this.$gAuth.signIn();
        if (googleUser) {
          const authResponse = googleUser.getAuthResponse();
          const userID = googleUser.getId();
          const formData = new FormData();
          formData.set('A', authResponse.id_token);
          formData.set('I', userID);
          formData.set('F', 'web');
          this.loginUser({ formData, platform: 'Google' });
        }
      } catch (error) {
        // On fail do something
        return false;
      }
      return true;
    },
    async appleLogin() {
      this.intentLoginSignUp('apple');
      try {
        const response = await window.AppleID.auth.signIn();
        const token = _.get(response, 'authorization.id_token');
        if (!_.isNil(token)) {
          const formData = new FormData();
          formData.set('A', token);
          if (!_.isNil(_.get(response, 'user.name'))) {
            formData.set('first_name', _.get(response, 'user.name.firstName'));
            formData.set('last_name', _.get(response, 'user.name.lastName'));
          }
          this.loginUser({ formData, platform: 'Apple' });
        }
      } catch (error) {
        // On fail do something
        return false;
      }
      return true;
    },
    async facebookLogin() {
      this.intentLoginSignUp('facebook');
      this.$segment.track('Facebook', {
        action: 'button_clicked',
      });

      sendSegmentEvent('Facebook', {
        action: 'button_clicked',
      }, null);

      tmAnalytics({ ga: this.$ga, category: 'registration.facebook', action: this.screen, label: window.page_data ? window.page_data.slug : '' }, ['ga']);

      const clientId = process.env.FB_APP_ID;
      const redirectUri = `${process.env.SITE_BASE_URL}tm_oauth/fb/`;
      const state = [location.protocol, '//', location.host, location.pathname].join('');
      location.href = `https://facebook.com/v5.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&display=page&state=${state}&scope=public_profile,email`;
    },
    async loginUser({ formData, platform }) {
      let queryParam = 'login-google';
      let analyticsTargetName = 'continue_with_google';
      if (platform === 'Apple') {
        queryParam = 'login-apple';
        analyticsTargetName = 'continue_with_apple';
      }

      formData.set('action', 'tm-login-form');
      formData.set('sa', queryParam);
      this.$wpAxios.post(`/wp-admin/admin-ajax.php?sa=${queryParam}`, formData,
        {
          httpsAgent: new https.Agent({
            rejectUnauthorized: false,
          }),
        }).then((resp) => {
        sendSegmentEvent(platform, {
          action: `${queryParam}_response`,
          status: resp.status,
          data: resp.data,
        }, null);

        // if it's a user who's not in our system, we send them to the birthdate screen next before actually logging in
        if (resp.data && resp.data.E === 'validate_birthdate') {
          formData.set('N', true);
          this.socialCredentials = { formData, platform };
          this.action = 'registration'
          this.step += 1;
          return;
        }

        this.redirecting = true;
        if (resp.data && resp.data.new === 1) {
          segmentAnalytics.signUp({
            context: {
              section: platform,
              view: 'new_account',
            },
            sessionId: this.user.sessionId,
          });
          
          sendSegmentEvent(platform, {
            action: 'new_user',
            navigator,
          }, null);

          window.location = `${window.location.href.split('?')[0]}?complete_onboarding=1`;
        } else {
          segmentAnalytics.logIn({
            target: {
              name: analyticsTargetName,
            },
            sessionId: this.user.sessionId,
          });
          // use set timeout so analytics rest call has time to execute before the page is redirected
          setTimeout(() => {
            let path = window.location.href.split('?')[0];
            // mobile social logins are returning with hashtag at the end, which was preventing page reload
            if (path.includes('#')) {
              path = path.slice(0,-1);
            }
            window.location = path.split('?')[0];
          }, 200);
        }
      }).catch((err) => {
        console.log('Error Logging In user --->', err);
      })
    },
  },
};

