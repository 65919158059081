
import _ from 'lodash';
import { FadeLoader } from '@saeris/vue-spinners';
import { log, staticPath } from '@/data/helpers';
import searchApi from '@/data/api/search';
/* eslint-disable no-param-reassign */
export default {
  name: 'TagSection',
  props: ['section', 'open', 'selectedTags'],
  components: { FadeLoader },
  data() {
    return {
      search: '',
      searchResults: [],
      doneLoadingSearchResults: true,
      loadingSearchResults: false,
      searchTimer: null,
    };
  },
  computed: {
    tags() {
      return _.get(this.section, 'tags', []);
    },
    searching() {
      return !_.isEmpty(this.search);
    },
    noSearchResults() {
      return this.searching && _.isEmpty(this.searchResults);
    },
    showMoreButton() {
      return this.searching && !this.doneLoadingSearchResults;
    },
    displayTags() {
      if (this.searching) {
        return this.searchResults;
      }

      // picked 16 arbitrarily
      return this.tags.slice(0, 16);
    },
    selectedTagsCount() {
      const tags = _.filter(this.selectedTags, t => t.sectionId === this.section.id);
      return tags.length > 0 ? `(${tags.length})` : '';
    },
  },
  methods: {
    staticPath,
    toggleAccordion() {
      this.$emit('toggleAccordion', this.section.id);
    },
    onInput() {
      this.loadingSearchResults = true;
      // essentially wait until they're done typing to loadSearchResults
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(this.loadSearchResults, 1000);
    },
    loadSearchResults(offset = 0) {
      // picked arbitrarily
      const limit = 16;
      searchApi.getSearchResults({ tab: 'tags', section: this.section.id, search: this.search, limit, offset }, this.$nodeAxios)
        .then((response) => {
          const tags = _.map(_.get(response, 'data.hits', []), tag => tag._source);
          this.searchResults = offset === 0 ? tags : [...this.searchResults, ...tags];
          this.doneLoadingSearchResults = tags.length < limit;
          this.loadingSearchResults = false;
        });
    },
    clearSearch() {
      this.search = '';
    },
    isTagSelected(tag) {
      // id field is different in default values vs search values
      const id = tag._id ? tag._id : tag.id;
      return _.findIndex(this.selectedTags, t => t.id === id) > -1;
    },
    toggleTag(tag) {
      // id field is different in default values vs search values
      const id = tag._id ? tag._id : tag.id;
      this.$emit('toggleTag', { id, label: tag.label, section: this.section.label, sectionId: this.section.id });
    },
    start(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      el.style.height = '';
    },
  },
};
