
import { staticPath } from '@/data/helpers';
import LoadingBar from './LoadingBar';

export default {
  name: 'Recommendations',
  props: ['completeAnimation'],
  components: { LoadingBar },
  data() {
    return {
      fields: ['groups', 'members', 'topics'],
      index: 0,
    };
  },
  computed: {
    loadedFields() {
      return this.fields.slice(0, this.index + 1);
    },
    doneLoading() {
      return this.index >= this.fields.length;
    },
  },
  methods: {
    staticPath,
    updateIndex() {
      this.index += 1;
    },
  },
  watch: {
    doneLoading: {
      immediate: true,
      handler() {
        if (this.doneLoading) {
          this.completeAnimation();
        }
      },
    },
  },
};
