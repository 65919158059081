
import _ from 'lodash';
import $ from 'jquery';
import segmentAnalytics from '@/libs/events';
import { log, err, isArray, isObject, isSet, tmAnalytics, staticPath } from '@/data/helpers';
import QuillTextArea from '@/components/QuillTextArea';

export default {
  name: 'IntroductionPost',
  components: { QuillTextArea },
  props: ['username', 'tags'],
  data() {
    return {
      quillArgs: {
        required: false,
        showCountDown: true,
        autosize: true,
        error: '',
        atts: {
          'data-min-length': 2,
          'data-max-length': 3000,
          'data-warn-at': 100,
          'data-limit-msg': 'You\'ve reached the character limit',
          maxlength: 3000,
        },
        value: '',
        showHashtagsButton: false,
        disableMentions: true,
      },
      submitting: false,
      promptIndex: 0,
      scrolling: false,
      showLeftScroll: false,
      showRightScroll: true,
      isMobile: false,
    };
  },
  watch: {
    username: {
      immediate: true,
      handler() {
        this.updateMessage();
      },
    },
    tags: {
      immediate: true,
      handler() {
        this.updateMessage();
      },
    },
    promptIndex: {
      immediate: true,
      handler() {
        this.updateMessage();
      },
    },
  },
  mounted() {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    this.isMobile = mediaQuery.matches;
  },
  computed: {
    messageTags() {
      let str = '<topic-new class="topic" data-denotation-char="#" data-id="undefined" data-value="MightyTogether" data-mighty_topic="false" name="MightyTogether">'
        + '<span contenteditable="false">#MightyTogether</span></topic-new>';
      _.forEach(this.tags, (t) => {
        const tag = t.label.replace(/\s/g, '');
        str += `<topic-new class="topic" data-denotation-char="#" data-id="undefined" data-value="${tag}" data-mighty_topic="false" name="${tag}">`
                + `<span contenteditable="false">#${tag}</span></topic-new>`;
      });
      return str;
    },
    disablePostSubmit() {
      return _.isEmpty(this.quillArgs.value) || this.submitting;
    },
    prompts() {
      return [
          "I'm here because",
          "I'm looking for",
          "I'm worried about",
          "I want to",
          "I've been diagnosed with",
          "I'm undiagnosed and",
          "I'm curious about",
        ]
    },
    // new Quill Editor is wrapping all elements in <p> tags, which is impacting the rendering of the hashtags of new posts, so I'm removing them
    strippedText() {
      const $temp = $("<div>").html(this.quillArgs.value);
      $temp.find("p").each(function() {
          $(this).replaceWith(this.childNodes);
      });

      return `<p>${$temp.html()}</p>`;
    },
  },
  methods: {
    staticPath,
    scroll(dir) {
      const prompts = document.querySelector('#prompts-scroll');
      if (dir === 'right') {
        prompts.scrollLeft += 200;
      } else if (dir === 'left') {
        prompts.scrollLeft -= 200;
      }
    },
    debounceFilterScroll: _.debounce(function() { this.newScroll(); }, 100),
    newScroll() {
        // mobile scrolling is jumpy so a lot of intricacies to get it smooth, just guess and check
        const container = document.querySelector('#prompts-scroll');
        const rightScroll = document.querySelector('#right-scroll');
        const leftScroll = document.querySelector('#left-scroll');

        if (this.scrolling) {
          return;
        }

        this.scrolling = true;

        if (!this.isMobile) {
          container.style.paddingRight = '50px';
          this.showRightScroll = true;
        }

        this.showLeftScroll = true;
        container.style.paddingLeft = '0px';

        if (container.scrollLeft === 0) {
          this.showLeftScroll = false;
          container.style.paddingLeft = '10px';
        } else if (container.scrollLeft + container.offsetWidth + 35 >= container.scrollWidth) {
          container.style.paddingRight = '30px';
          this.showRightScroll = false;
        } else if (this.isMobile) {
          this.showRightScroll = true;
        }

        this.scrolling = false;
    },
    updateMessage() {
      // eslint-disable-next-line  max-len
      this.quillArgs.value = `Hi, my name is ${this.username}. ${this.prompts[this.promptIndex]} </br></br>${this.messageTags}`;
    },
    // save all the form values in our data store
    saveForm() {
      const data = {
        type: 'THOUGHT',
        title: "I'm new here!",
        body: this.strippedText,
        new_member_post: true,
      };

      this.$store.commit('setLastPostArgs', data);
    },
    skipToMyFeed() {
      this.$emit('close');
      segmentAnalytics.choose({
        context: {
          section: 'skip_post',
          view: 'onboarding_post',
        },
        target: {
          name: 'skip_to_home',
          type: 'skip',
        },

      });
    },
    selectPrompt(idx) {
      this.promptIndex = idx;
    },
    savePost() {
      this.submitting = true;
      segmentAnalytics.post({
        context: {
          section: 'submit_post',
          view: 'onboarding_post',
        },
        target: {
          name: 'submit_post',
          type: 'button',
        },

      });
      tmAnalytics({ ga: this.$ga, category: 'registration.firstpost', action: 'choose', label: window.page_data ? window.page_data.slug : '' }, ['ga']);
      this.saveForm();
      const me = this;
      this.$store.dispatch('savePost')
        .then((rawResp) => {
          // because data/modules/hashtags.js AND data/modules/voices.js BOTH have an action named savePost, the resulting rawResp here is
          // actally an array containing both action return values. the hashtags.js one is undefined, so we can filter that one out, and
          // then the only one left is the one from voices.js, which is the one we want an need for this function
          let resp;
          if (isArray(rawResp)) {
            resp = rawResp.filter(c => !!c);
            resp = resp.shift();
          } else if (isObject(rawResp)) {
            resp = rawResp;
          } else {
            throw new Error('invalid response');
          }
          const post = resp.data;

          me.$store.dispatch('voiceEvent', {
            category: 'submit_post.compose_thought.submit_button',
            action: 'choose',
            label: post.slug,
          });

          this.$emit('close');
        })
        .catch((args) => {
          this.submitting = false;
          err('savePost[error]:', args);
          this.handleExtendedErrors(args);
        });
    },
    handleExtendedErrors(error) {
      if (isSet(error) && isSet(error.response) && isSet(error.response.data) && isSet(error.response.data.data)) {
        const realData = error.response.data.data;
        // body errors
        if (isSet(realData.body)) {
          if (realData.body.indexOf('body_length_invalid') > -1) {
            this.quillArgs.error = 'The length of your thought is too long.';
          }
          if (realData.body.indexOf('body_missing') > -1) {
            this.quillArgs.error = 'The body of your thought must not be empty.';
          }
        }
      }
    },
  },
};

