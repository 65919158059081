
export default {
  name: 'LoadingBar',
  props: ['callback'],
  data() {
    return {
      progress: 0,
      loading: null,
    };
  },
  mounted() {
    // arbitrary speed at which to show loading
    this.loading = setInterval(() => {
      this.progress += 0.75;
    });
  },
  computed: {
    columnWidth() {
      return `${this.progress}%`;
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler() {
        if (this.progress >= 100) {
          clearInterval(this.loading);
          this.callback();
        }
      },
    },
  },
};
