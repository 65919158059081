
import _ from 'lodash';
import { mapGetters } from "vuex";
import { staticPath, sendSegmentEvent } from '@/data/helpers';
import newslettersApi from '@/data/api/newsletters';
import Newsletter from './Newsletter';

export default {
    name: 'Subscribe',
    components: { Newsletter },
    props: ['email', 'userId', 'callback', 'tags'],
    data() {
        return {
            newsletters: [],
            selected: [],
            scrolling: false,
            showLeftScroll: false,
            showRightScroll: false,
            isMobile: false,
            saving: false,
        }
    },
    async mounted() {
        const newsletters = await newslettersApi.getNewsletters(this.$nodeAxios);
        this.newsletters = newsletters.filter(n => !!n.onb_feature && n.onb_feature_order !== undefined).sort((a, b) => a.onb_feature_order - b.onb_feature_order);
        // start with the Main Newsletter selected
        const dailyNewsletter = this.newsletters[0];
        if (dailyNewsletter) this.selected.push(dailyNewsletter.cordial_id);

        // if the user selected the Migraine tag on the previous screen, also start with that selected
        if (this.tags.length) {
            const migraine = this.tags.find(t => t.label === 'Migraine');
            if (migraine) this.selected.push('migraine');
        }

        const mediaQuery = window.matchMedia('(max-width: 768px)');
        this.isMobile = mediaQuery.matches;
    },
    computed: {
        ...mapGetters({
            user: 'user',
        }),
        sessionId() {
            return _.get(this.user, 'sessionId', '');
        }
    },
    methods: {
        staticPath,
        scroll(dir) {
            const prompts = document.querySelector('#newsletters-scroll');
            if (dir === 'right') {
                prompts.scrollLeft += 200;
            } else if (dir === 'left') {
                prompts.scrollLeft -= 200;
            }
        },
        debounceFilterScroll: _.debounce(function() { this.newScroll(); }, 10),
        newScroll() {
            // mobile scrolling is jumpy so a lot of intricacies to get it smooth, just guess and check
            const container = document.querySelector('#newsletters-scroll');
            const rightScroll = document.querySelector('#newsletters-right-scroll');
            const leftScroll = document.querySelector('#newsletters-left-scroll');

            if (this.scrolling) {
                return;
            }

            this.scrolling = true;

            if (!this.isMobile) {
                container.style.paddingRight = '50px';
                this.showRightScroll = true;
            }

            this.showLeftScroll = true;
            container.style.paddingLeft = '0px';

            if (container.scrollLeft === 0) {
                this.showLeftScroll = false;
                container.style.paddingLeft = '10px';
            } else if (container.scrollLeft + container.offsetWidth + 35 >= container.scrollWidth) {
                container.style.paddingRight = '30px';
                this.showRightScroll = false;
            } else if (this.isMobile) {
                this.showRightScroll = true;
            }

            this.scrolling = false;
        },
        handleClick(id) {
            let idx = _.findIndex(this.selected, cordialId => cordialId === id);
            if (idx > -1) {
                this.selected.splice(idx, 1);
            } else {
                this.selected.push(id);
            }
        },
        async trackClickThrough() {
            const properties = {
                context: { 
                    section: 'Newsletter Selection',
                    view: 'new_onboarding',
                    path: this.$route.path,
                    subscribes: this.selected.length,
                },
                userId: this.userId || '',
                sessionId: this.sessionId,
            };
            await sendSegmentEvent('Choose', properties, null);
        },
        async subscribe() {

            // event to analyze the # of users to click through and avg selected Newsletters
            await this.trackClickThrough();

            if (this.selected.length === 0) {
                this.callback();
                return;
            }

            this.saving = true;
            const data = {
                email: this.email,
                newsletters: {},
            };

            _.forEach(this.selected, id => { data['newsletters'][id] = 1; });

            await newslettersApi.subscribeToNewsletters(data, this.$nodeAxios)
                .then(async () => {
                    const context = {
                        section: 'Newsletter Selection',
                        view: 'new_onboarding',
                        path: this.$route.path,
                    };
                    const promises = [];
                    for (let newsletter of this.selected) {
                        const properties = {
                            context: { ...context, newsletter },
                            userId: this.userId || '',
                            sessionId: this.sessionId,
                        }
                        promises.push(() => sendSegmentEvent('Newsletter Signup', properties, null));
                    }

                    await Promise.all(promises.map((func) => func()));

                    if (this.callback) this.callback();
                });
        },
    },
    watch: {
        newsletters: {
            immediate: true,
            handler() {
                if (this.newsletters.length > 3) this.showRightScroll = true;
            }
        },
    }
}
