
export default {
    name: 'Newsletter',
    props: ['newsletter', 'isSelected'],
    data() {
        return {
        }
    },
    computed:{ 
        newsletterImage() {
            if (this.newsletter.image) {
                return this.newsletter.image;
            }

            return staticPath('./newsletter_default.svg');
        },
    },
    methods: {
      toggleSelected() {
        this.$emit('click', this.newsletter.cordial_id);
      },
    }
}
