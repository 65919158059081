
import vClickOutside from 'v-click-outside';
import _ from 'lodash';

export default {
  name: 'DateSelector',
  data() {
    return {
      open: false,
      search: '',
      searchResult: null,
      searchTimer: null,
    };
  },
  props: ['selectedYear'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    years() {
      return _.map(_.range(0, 100), year => new Date().getFullYear() - year);
    },
  },
  methods: {
    // just a neat feature for scrolling to item in options if user types
    searchOptions(event) {
      if (this.open) {
        // using a timer to reset their search term everytime a user searches a new value
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
          this.searchTimer = null;
        }
        this.searchTimer = setTimeout(() => { this.search = ''; }, 2000);
        // if a number is pressed, add that number to the existing search term and see if there is a corresponding Year
        // for example if the search equals "193", the _find will find the first year to match that string
        if (event.keyCode >= 48 && event.keyCode <= 57) {
          this.search += event.key;
        } else if (event.keyCode === 8) {
          // if a user hits the back key, remove the previous key from the existing Search term
          this.search = this.search.slice(0, -1);
        }

        const searchResult = _.find(this.years, year => year.toString().substring(0, this.search.length) === this.search);
        if (searchResult) {
          // if a searchResult exists, within the Dropdown, scroll to that option
          this.searchResult = searchResult;
          const option = document.getElementById(`date-option-${searchResult}`);
          const options = document.getElementById('date-options-year');
          options.scrollTop = (option.offsetTop - 50);
        }
      }
    },
    toggleDropdown() {
      this.search = '';
      this.searchResult = null;
      this.open = !this.open;
    },
    closeDropdown() {
      if (this.open) {
        this.search = '';
        this.searchResult = null;
        this.open = false;
      }
    },
    // if a user hits tab after Searching, this will select the Search Result if one exists
    selectSearchResult() {
      if (this.searchResult) {
        this.selectOption(this.searchResult);
      }
    },
    selectOption(option) {
      this.closeDropdown();
      this.$emit('change', option);
    },
  },
};
